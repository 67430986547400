import { DatePicker } from '@progress/kendo-react-dateinputs'
import { sizedUrl } from 'app/utils'
import classnames from 'classnames'
import { DEFAULT_SEGMENT_COUNT, LoadSegmentCodes } from 'components/common/load-partner-codes/component'
import { ArticleImageUpload } from 'components/content-pool/article-image-upload'
import { DEFAULT_CATEGORY_TYPE_ID, DEFAULT_CATEGORY_TYPE_NAME, Font } from 'components/content-pool/constants'
import { SourceSection } from 'components/content-pool/types'
import { DropdownComponent, DropdownKey, DropdownOption } from 'components/dropdown/component'
import { GeneratedSummaryModalComponent } from 'components/generate-text'
import { LinkOutIcon } from 'components/icons/link-out'
import { UrlInput } from 'components/input/component'
import { Loading, SIZES } from 'components/loading'
import * as Validation from 'components/validation/validations'
import { RasaContext } from 'context'
import { differenceInSeconds } from 'date-fns'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { Dataset } from 'generic/dataset'
import { isFalsey } from 'generic/utility'
import { isEmpty, startCase } from 'lodash'
import * as React from 'react'
import { Badge, Button, Input } from 'reactstrap'
import { DATEPICKER_DATE_FORMAT, Roles } from 'shared/constants'
import { isBeforeCutoff, isInCutoffWindow } from 'shared/data-layer/issues'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import * as Modals from 'shared/modals'
import { IMAGE } from 'shared_server_client/constants'
import { resetToStartOfDayZoned } from 'shared_server_client/dates'
import { BillingPlanDetailCode } from 'shared_server_client/types/billing_plan'
import { DEFAULT_MAX_SPONSORED_CONTENT } from 'shared_server_client/constants'
import { getSponsoredName } from 'shared_server_client/utils'
import { ImageGalleryModal } from '../../../common/image-gallery/modal'
import {
  ARTICLE_DESCRIPTION_CHANGE_MESSAGE, ARTICLE_FETCH_ERROR_MESSAGE, ClearScheduledContentDataState,
  CONTENT_ALREADY_EXISTS, CONTENT_ALREADY_EXISTS_MESSAGE, CONTROL_CONTAINER,
  CONTROL_FETCH_BUTTON, FEATURE_TYPE, InitialScheduledContentModalState,
  INVALID_IMAGE_URL_MESSAGE
} from '../constants'
import { Tinymce_Default_Config, TinymceEditorComponent } from '../../../tinymce-editor/tinymceditor'
import './_styles.scss'
import { isUrlsSame } from '../../../../shared/utils'
import { Editor as TinyMCEEditor } from "tinymce";
import { ModalSize } from 'shared/modals'

export interface AddArticleModalProps extends Modals.ModalComponentProps {
  afterClose?: () => any,
  afterSave?: (data: any) => any,
  allowGeneratingViaChatGPT?: boolean,
  allowSegment?: boolean,
  defaultSegmentCode?: string,
  forceReload?: () => any,
  isScheduled?: boolean,
  locationOptions?: any[],
  featureType?: FEATURE_TYPE,
}

export interface Location {
  key: string
  value: string
}

export interface AddArticleModalState extends Modals.ModalComponentState {
  allSections?: any[]
  activeCommunity?: any
  currentTime?: Date
  dirtyFields: string[]
  endDate: Date
  errorMessage: string
  hasSectionAccess?: boolean
  hasImageGalleryAccess?: boolean
  imageDisplayType?: string
  imageName: string
  imageUrl: string
  isDirty: boolean
  isEditingImage: boolean
  isLoading: boolean
  isSuperUser: boolean
  isUploading: boolean
  isValid?: boolean
  location: Location
  maxSponsoredPosts?: number
  originalUrl: string
  retrievedUrl: string
  selectedLocation: DropdownOption
  locationOptions?: DropdownOption[]
  modalErrorMessage: string
  showDescriptionChangeMessage: boolean
  selectedSection: DropdownOption
  sections?: DropdownOption[]
  showSaveConfirmationModal?: boolean
  showSegments?: boolean
  sourceUrl: string
  startDate: Date
  typeId: string
  useUrl: boolean
  validUrl: boolean
}

export class AddArticleModalBaseComponent extends Modals.GenericModalComponent<
AddArticleModalProps,
AddArticleModalState
> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  private modalKey: string = 'addArticleModal'
  private _interval: any
  private _tinymceInstance: TinyMCEEditor
  constructor(props: AddArticleModalProps, key: string) {
    super(props, key)
    this.modalKey = key
    this.state = InitialScheduledContentModalState
    this.uploadImage = this.uploadImage.bind(this)
    this.clearImage = this.clearImage.bind(this)
    this.updateImageUrl = this.updateImageUrl.bind(this)
    this.aiGenerateImageClick = this.aiGenerateImageClick.bind(this)
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.role),
    ]).then(([activeCommunity, role]) => {
      const galleryFeature: any = activeCommunity.billingInfo.currentPlan.details.find((x) =>
        x.billing_plan_code === BillingPlanDetailCode.IMAGE_GALLERY)
      const sponsoredFeature: any = activeCommunity.billingInfo.currentPlan.details.find((x) =>
        x.billing_plan_code === BillingPlanDetailCode.MAX_SPONSORED_POSTS)
      this.updateProperty('featureType', this.props.featureType)
      this.getSections(activeCommunity.communityId)
      this.setState({
        activeCommunity,
        hasSectionAccess: activeCommunity.billingInfo.usageStats.currentPlanMaxSections > 0,
        hasImageGalleryAccess: galleryFeature && galleryFeature.limit_val !== 0,
        maxSponsoredPosts: sponsoredFeature ? sponsoredFeature.limit_val : DEFAULT_MAX_SPONSORED_CONTENT,
        isValid: false,
        isSuperUser: role === Roles.super_admin,
      }, () => {
        this.getLocationOptions(this.state.maxSponsoredPosts)
        this.setIntervals()
      })
    })
  }

  public componentDidUpdate(prevProps: any, prevState: any) {
    const data = this.props.data[this.key].data
    if (data !== prevProps.data[this.key].data) {
      const { url } = data
      if (data.isCloneItem && !this.state.validUrl && isEmpty(this.state.sourceUrl)) {
        this.validateUrl(url)
      }
    }
  }

  public renderChildren(data) {
    const { contentCategory, customTags, description, endDate, id, image, hostedImage, nlpTags,
      publisher, sourceTags, startDate, title, url, rank, image_alt_text } = data
    return this.state.isSaving
    ? <Loading size={SIZES.LARGE}/>
    : <div className="scheduled-article-modal-body sched-art">
        <div className="full-width-container">
          <div className="first-row-container">
          <GeneratedSummaryModalComponent
            saveModal={(key, modalData) => {
              if ( modalData.description ) {
                this.updateProperty('description', modalData.description)
              }
              return this.props.closeModal(key)
            }}
            title={title}
            description={description}
            url={url}
            data={this.props.data}
            closeModal={this.props.closeModal}
            updateModal={this.props.updateModal}
            size={Modals.ModalSize.Large} />
            {this.renderArticleUrlField(data)}
          </div>
          <div className={classnames(this.getClassNames(data, CONTROL_CONTAINER))}>
          <div className="second-row-container">
            <h5 className="heading">Title</h5>
            <div className="second-row-inner-container">
            <Input type="text"
                  value={title ? title : ''}
                  onChange={(e) => this.updateProperty('title',  e.target.value)}/>
            </div>
          </div>
          <div className="third-row-container">
            <h5 className="heading">Source</h5>
            <div className="third-row-inner-container">
              <Input type="text"
                    value={publisher ? publisher : ''}
                    onChange={(e) => this.updateProperty('publisher',  e.target.value)}/>
            </div>
          </div>
            <div className="second-row-container">
              <div className="heading">
                <h5>Excerpt</h5>
                {this.props.allowGeneratingViaChatGPT &&
                  <Button className="generate"
                          onClick={() => this.props.openModal(
                            GeneratedSummaryModalComponent.key, {source: 'edit-article'})}>
                    <span>AI Generated</span>
                  </Button>}
              </div>

            <div className="fourth-row-inner-container">
              <TinymceEditorComponent
                html={description ? description : ''}
                height='413'
                tinymceConfig={{
                  ...Tinymce_Default_Config,
                  menu: {
                    file: { title: '', items: '' },
                    edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace' },
                    view: { title: '', items: '' },
                    insert: { title: 'Insert', items: 'link' },
                    format: { title: 'Format', items: 'bold italic underline | forecolor backcolor | removeformat' },
                    table: { title: '', items: '' },
                    tools: { title: '', items: '' },
                  },
                  plugins: [
                    'emoticons', 'lists', 'link', 'searchreplace',
                  ],
                  toolbar: 'bold italic underline | forecolor backcolor | bullist numlist | emoticons | link',
                }}
                instance={(editor: TinyMCEEditor) =>  this.tinymceInstance(editor)}
                onChange={(html: string) => this.articleDescription(html) }
                showCharacterCount={true} />
            </div>
          </div>
        </div>
        <div className="body-container">
          <div className="left-side-container">
            <div className={classnames(this.getClassNames(data, CONTROL_CONTAINER))}>
              <div className="first-row-container">
                <h5>Article Preview</h5>
                <div className="preview-container">
                  {<ArticleImageUpload isUploading={this.state.isUploading} allowAIGeneration={true}
                    allowUseUrl={true}
                    allowImageLibrary={true}
                    imageUrl={sizedUrl(image, 250, 400)}
                    font={Font.LARGE}
                    updateImageUrl={this.updateImageUrl}
                    aiGenerateImageClick={this.aiGenerateImageClick}
                    uploadImage={this.uploadImage} clearImage={this.clearImage}
                    imageHeight={hostedImage ? hostedImage.height : null}
                    imageWidth={hostedImage ? hostedImage.width : null}
                    selectImageLibrary={this.openImageGalleryModal}/>}
                </div>
                <div>
                  <h5 className="heading">Alt Text</h5>
                  <Input type="text"
                         value={image_alt_text ? image_alt_text : ''}
                         onChange={(e) => this.updateProperty('image_alt_text', e.target.value)}/>
                </div>
              </div>
            </div>
          </div>
          <div className="right-side-container">
            <div className={classnames(this.getClassNames(data, CONTROL_CONTAINER))}>
              <div className="single-row-container">
                {this.props.allowSegment &&
                <div className={`third-row-inner-container right-margin ${!this.state.showSegments ? 'display-none' : ''}`}>
                  <h5>Segment</h5>
                  <LoadSegmentCodes
                    hideLabel={true}
                    setSegmentData={this.setSegmentData}
                    segmentCode={data.community_partner_code}
                    setSegmentCode={this.setSegmentCode}/>
                </div>}
                {this.state.hasSectionAccess && this.state.sections.length > 0 &&
                <div className="third-row-inner-container">
                  <h5>Section</h5>
                  <DropdownComponent
                    disabled={this.props.isScheduled && id}
                    data={this.state.sections}
                    selected={this.getSelectedSection(contentCategory)}
                    onChange={this.onDropdownChange}/>
                </div>}
              </div>
            { this.props.isScheduled &&
              <div className="fifth-row-container">
                <div className="start-date-container">
                  <h5>Start Date</h5>
                  <DatePicker
                    className="modal-schedule-date-picker"
                    format={DATEPICKER_DATE_FORMAT}
                    value={startDate ? startDate : null}
                    onChange={(e) => this.updateProperty('startDate', e.target.value)}
                  />
                </div>
                <div className="end-date-container">
                  <h5>End Date</h5>
                  <DatePicker
                    className="modal-schedule-date-picker"
                    format={DATEPICKER_DATE_FORMAT}
                    value={endDate ? endDate : null}
                    onChange={(e) => this.updateProperty('endDate', e.target.value)}
                  />
                </div>
              { this.props.featureType === FEATURE_TYPE.SPONSORED_POST ?
                <div className="location-container">
                  <h5>Location</h5>
                  <div className="fourth-row-container">
                  <DropdownComponent
                    data={this.state.locationOptions}
                    selected={rank}
                    onChange={(e) => this.updateProperty('rank', e.selected.value)}/>
                  </div>
                </div> :
                <div className="rank-container">
                  <h5>Rank</h5>
                  <div className="fourth-row-container">
                    <Input type="number"
                          value={rank}
                          max="20"
                          onChange={(e) => {
                            if (Number(e.target.value) > 20) {
                              e.target.value = '20'
                            }
                            this.updateProperty('rank',  e.target.value)}
                          }/>
                  </div>
                </div>
              }
              </div>
            }
              <div className="tags-container">
                <div className="custom-tag-editor title-words">
                  <h5>Tags</h5>
                  <Input
                    max={50}
                    id="custom-tag-input"
                    className="tag-input"
                    placeholder={'Type new tags here'}
                    onKeyUp={(event) => {
                      if (event.key === 'Enter') {
                        const target = event.target as HTMLInputElement
                        this.addTag(target.value)
                        target.value = ''
                      }
                    }}
                  />
                  <p id="custom-input-text-bottom" className="inputtextbottom">Hit Enter When Done</p>
                  <div className="tag-container bottom-space">
                    {customTags
                    && customTags.split(',').map(
                      (tag: string, index: number) =>
                    <Badge className="custom" key={`custom-tag-${index}`}
                      color="secondary">{startCase(tag.toLowerCase())} <span className="badge-close"
                      onClick={(e) => this.removeTag({tag})}>x</span></Badge>)}
                  </div>
                  <div className="tag-container">
                    {nlpTags
                    && nlpTags.split(',').map((tag: string, index: number) =>
                    <Badge className="nlp" key={`nlp-tag-${index}`}
                      color="secondary">{tag}</Badge>)}
                  </div>
                  <div className="tag-container">
                    {sourceTags
                    && sourceTags.split(',').map(
                      (tag: string, index: number) =>
                    <Badge className="source" key={`source-tag-${index}`}
                      color="secondary">{tag}</Badge>)}
                  </div>
                </div>
              </div>
              {this.state.modalErrorMessage &&
              <div className="error-container">
                {this.state.modalErrorMessage}
              </div>}
            </div>
          </div>
          {(this.state.hasImageGalleryAccess || this.state.isSuperUser) &&
              <ImageGalleryModal
                  data={this.props.data}
                  onSelectImageFromGallery={(selectedImage: {url: string, imageWidth: number, imageHeight: number}) => {
                    this.setState({
                      sourceUrl: selectedImage.url,
                      validUrl: Validation.validateUrl(selectedImage.url).valid,
                      useUrl: true
                    })
                    this.updateProperty('image', selectedImage.url)
                    this.updateProperty('hostedImage', {
                      url: selectedImage.url,
                      height: selectedImage.imageHeight,
                      width: selectedImage.imageWidth,
                    })
                  }}
                  closeModal={this.props.closeModal}
                  closeButtonText={'Cancel'}
                  size={ModalSize.Large}/>
          }
        </div>
      </div>
    </div>
  }

  protected tinymceInstance = (instance: TinyMCEEditor) => {
    this._tinymceInstance = instance
  }

  protected articleDescription = (description: string) => {
    if(this._tinymceInstance.isDirty()) {
      this.updateProperty('description', description)
      this.setState({
        showDescriptionChangeMessage: true,
      })
    }
  }

  protected openImageGalleryModal = () => {
    this.props.openModal(ImageGalleryModal.key, {})
  }

  protected renderArticleUrlField = (data) => {
    const { id, url } = data
    return <>
      <div className="heading">
        <h5>Article URL</h5>
        {id &&
          <a href={this.state.sourceUrl || url} target="_blank" rel="noopener">
            <LinkOutIcon/>
            <span>{this.props.data.url}</span>
          </a>}
      </div>
      <div className="first-row-inner-container">
        {!id
          ? <div className="url-input-and-fetch-container">
            {
              this.state.retrievedUrl ?
                <div className="url-input-container">
                  <DropdownComponent
                    selected={url}
                    data={[
                      {key: '', description: 'Please Select'},
                      {key: this.state.originalUrl, description: 'I want exactly the url I submitted'},
                      {key: this.state.retrievedUrl, description: 'I want the one you retrieved'},
                    ]}
                    onChange={(e) => {
                      this.updateProperty('override_url', e.selected.key === this.state.originalUrl)
                      this.updateProperty('url', e.selected.key)
                    }} />
                  <span className="input-tip">
                    We retrieved a different url than the one you submitted, please select which one you'd like to use
                  </span>
                </div>
                : <UrlInput
                  disabled={!!this.state.originalUrl}
                  errorMessage={this.state.errorMessage}
                  onChange={this.updateSourceUrl}
                  onKeyPress={this.handleKeyPress}
                  placeholder={'Please input your article url here'}
                  valid={this.state.validUrl}
                  value={this.state.sourceUrl || url}/>
            }
            <div className={classnames(this.getClassNames(data, CONTROL_FETCH_BUTTON))}>
              {this.state.originalUrl ?
                <span className={classnames('fetch-btn clickable-item', this.state.validUrl ? 'valid-url' : '')}
                      onClick={this.resetModal}>
                                <label className="clickable-item">
                                    Reset
                                </label>
                            </span>
                :
                <span className={classnames('fetch-btn clickable-item', this.state.validUrl ? 'valid-url' : '')}
                      onClick={() => this.validateFetch(this.state.sourceUrl || url)}>
                                <label className="clickable-item">
                                    Fetch
                                </label>
                            </span>}
            </div>
          </div>
          : <div className="url-display-box">
            {this.state.sourceUrl || url}
          </div>}
      </div>
    </>
  }

  protected getTitleText(data: any): string {
    const articleType = this.props.featureType === FEATURE_TYPE.SPONSORED_POST ? 'Sponsored' :
    this.props.isScheduled ? 'Scheduled' : ''
    return data.typeId
      ? `Edit ${articleType} Article`
      : (data.isCloneItem) ? `Clone ${articleType} Article` : `Add ${articleType} Article`
  }

  protected saveDisabled(data: any): boolean {
    const { description, endDate, id, publisher, startDate, title, url } = data
    const isMissingData = this.props.isScheduled ? isFalsey(description && endDate && publisher && startDate && title)
      : isFalsey(description && publisher && title)
    // no id => create article
    // id => update article
    return !id
    ? isMissingData || !Validation.validateUrl(url).valid
    : isMissingData || this.state.dirtyFields.length === 0
  }

  protected xButtonClick(data: any) {
    const currentIsSuperUser = this.state.isSuperUser
    this.setState({
      ...ClearScheduledContentDataState,
      isSuperUser: currentIsSuperUser,
    })
    this.props.closeModal(this.modalKey)
    if (this.props.afterClose) {
      this.props.afterClose()
    }
  }

  protected updateProperty(property, value) {
    this.props.updateModal(this.modalKey, {
      name: property,
      value,
    })
    this.setState({
      dirtyFields: [...this.state.dirtyFields, property],
    })
  }

  protected updateSourceUrl = (e) => {
    this.updateProperty('url', e.target.value)
    this.validateUrl(e.target.value)
    this.setState({ sourceUrl: e.target.value })
  }

  protected validateUrl = (url: string) => {
    this.setState({
      errorMessage: '',
      validUrl: Validation.validateUrl(url).valid,
    })
  }

  protected fetch = (inputUrl: string): Promise<any> => {
    const articleValidateEndpoint: string =
      `${AjaxWrapper.getServerUrl()}/article/${this.state.activeCommunity.communityId}/find`
    return AjaxWrapper.ajax(articleValidateEndpoint, HttpMethod.POST, {url: inputUrl})
  }

  protected validateFetch = (inputUrl: string) => {
    this.setState({isUploading: true})
    return this.fetch(inputUrl)
    .then((result: any) => {
      if (result) {
        const isUrlSame = isUrlsSame(result.url, inputUrl)
        if(!isUrlSame){
          this.updateProperty('override_url', true)
        }
        this.setState({
          isUploading: false,
          isValid: true,
          originalUrl: inputUrl,
          retrievedUrl: isUrlSame ? '' : result.url
        })
        this.updateProperty('title', result.title)
        this.updateProperty('publisher', result.publisher)
        this.updateProperty('image', result.image)
        this.updateProperty('description', result.description)
        this.updateProperty('rank', 1)
      } else {
        this.setState({
          errorMessage: INVALID_IMAGE_URL_MESSAGE,
          isUploading: false,
          validUrl: false,
          isValid: true,
        })
      }
    })
    .catch((err) => {
      this.setState({
        errorMessage: ARTICLE_FETCH_ERROR_MESSAGE,
        isUploading: false,
        validUrl: false,
        isValid: true,
      })
    })
  }

  protected resetModal = () => {
    const currentIsSuperUser = this.state.isSuperUser
    this.setState({
      ...ClearScheduledContentDataState,
      isSuperUser: currentIsSuperUser,
    })
    this.clearImage()
    this.updateProperty('url', '')
    this.updateProperty('title', '')
    this.updateProperty('publisher', '')
    this.updateProperty('description', '')
    this.updateProperty('rank', 1)
    this.updateProperty('override_url', false)
  }

  protected clearImage() {
    this.updateProperty('image', '')
    this.updateProperty('hostedImage', {
      url: '',
      height: null,
      width: null,
    })
  }

  protected updateImageUrl(imageUrl: string) {
    this.updateProperty('image', imageUrl)
    this.updateProperty('hostedImage', null)
  }

  protected aiGenerateImageClick = () => {
    this.setState({
      isUploading: true,
    })
    const payload = {
      title: this.props.data[this.key].data.title,
      description: this.props.data[this.key].data.description,
    }
    const url: string = `${AjaxWrapper.getServerUrl()}/ai/${this.state.activeCommunity.communityId}/generate-article-image`;
    return AjaxWrapper.ajax(url, HttpMethod.POST, payload)
      .then((hostedImage: any) => {
        this.setState({
          isUploading: false,
        })
        this.updateProperty('image', hostedImage.url)
        this.updateProperty('hostedImage', hostedImage)
      })
      .catch((error) => {
        this.setState({
          isUploading: false,
        })
      })
  }

  protected uploadImage(image: any) {
    this.setState({
      isUploading: true,
    })
    const formData = new FormData()
    formData.append(IMAGE, image)
    const url: string = `${AjaxWrapper.getServerUrl()}/${this.state.activeCommunity.communityId}/image`;
    return AjaxWrapper.ajax(url, HttpMethod.POST, formData, null)
      .then((hostedImage: any) => {
        this.setState({
          isUploading: false,
        })
        this.updateProperty('image', hostedImage.url)
        this.updateProperty('hostedImage', hostedImage)
      })
      .catch((error) => {
        this.setState({
          isUploading: false,
        })
      })
  }

  protected addTag(tag) {
    const customTags = !this.props.data[this.key].data.customTags ? [] :
    this.props.data[this.key].data.customTags.split(',')
    if (tag && customTags.indexOf(tag.trim()) < 0 && customTags.length < 5) {
      customTags.push(tag.trim())
      this.updateProperty('customTags', customTags.join(','))
    }
  }

  protected removeTag(obj) {
    const customTags = this.props.data[this.key].data.customTags.split(',').filter((item) =>
      item !== obj.tag)
    this.updateProperty('customTags', customTags.join(','))
  }

  protected formatConflicts(conflicts: [], timezone: string) {
    return conflicts.map((c: any) => {
      const startDate = resetToStartOfDayZoned(c.start_date, timezone)
      const endDate = resetToStartOfDayZoned(c.end_date, timezone)
      return startDate + '/' + endDate
    })
  }

  protected fieldChanged(field: string): boolean {
    return this.state.dirtyFields.find((f) => f === field) !== undefined
  }

  protected createOrUpdate(payload: any): Promise<any> {
    return Promise.resolve(null)
  }

  protected doSave(data: any) {
    this.setState({isSaving: true})
    return this.createOrUpdate(data)
    .then(() => {
      const currentIsSuperUser = this.state.isSuperUser
      this.setState({
        ...ClearScheduledContentDataState,
        isSuperUser: currentIsSuperUser,
      })
      if (this.props.forceReload) {
        this.props.forceReload()
      }
      if (this.props.afterSave) {
        this.props.afterSave(data)
      }
      this.props.closeModal(`${data.content_type}Modal`)
      return Promise.resolve({})
    })
    .catch((error) => {
      const {timezone} = data
      this.setState({
        modalErrorMessage: error.response.message === CONTENT_ALREADY_EXISTS
          ? (CONTENT_ALREADY_EXISTS_MESSAGE + ' Conflicts: ' +
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            this.formatConflicts(error.response.error.conflicts, timezone))
          : error.response.message,
        isUploading: false,
        isSaving: false,
      })
      return Promise.reject({})
    }).finally(() => this.setState({isSaving: false}))
  }

  protected getSaveText(data: any): string {
    return this.props.isScheduled ? 'Save & Schedule' : data.typeId ? 'Save Changes' : 'Add Article'
  }

  protected handleKeyPress = (target: any) => {
    if (target.charCode === 13 && this.state.validUrl) {
      this.validateFetch(this.state.sourceUrl)
    }
  }

  protected getFooterContent(data: any) {
    if (this.state.activeCommunity.nextIssue &&
        this.state.activeCommunity.nextIssue.schedule.start_date &&
      isInCutoffWindow(this.state.activeCommunity.nextIssue, this.state.currentTime) &&
      !this.props.isScheduled && !data.typeId) {
      return <>
        <div className="footer">
          <em>
            Because you are in your cutoff window,
            this article will be boosted to ensure it appears in your next newsletter.
          </em>
        </div>
        {this.state.showDescriptionChangeMessage && <div className="description-error-container">
          {ARTICLE_DESCRIPTION_CHANGE_MESSAGE}
        </div>}
      </>
    } else if (this.state.showDescriptionChangeMessage) {
      return <div className="description-error-container">
          {ARTICLE_DESCRIPTION_CHANGE_MESSAGE}
      </div>
    } else {
      return null
    }
  }

  protected setSegmentCode = (code: any) => {
    this.updateProperty('community_partner_code', code.key)
  }

  protected setSegmentData = (configData: any) => {
    this.setState({
      showSegments: configData && configData.length > DEFAULT_SEGMENT_COUNT,
    })
    const community_partner_code = this.props.data[this.key].data.community_partner_code
    if (!community_partner_code && configData.length > DEFAULT_SEGMENT_COUNT) {
      this.updateProperty('community_partner_code', this.props.defaultSegmentCode)
    }
  }

  protected getSections = (community) => {
    new Dataset()
    .loadCommunityDataset('sections', community)
    .then((response) => {
      const sections = response[0] || []
      this.setState({
        allSections: sections,
        sections: this.normalizeSections(sections),
      })
    })
  }

  protected normalizeSections = (sections: SourceSection[]): DropdownOption[] => {
    const normalizedSections = sections.map((s) => ({
      description: s.name,
      key: s.category_type_id,
      value: s.category_type,
    }))
    if (sections.length) {
      normalizedSections.unshift({
        description: 'No section',
        key: DEFAULT_CATEGORY_TYPE_ID,
        value: DEFAULT_CATEGORY_TYPE_NAME,
      })
    }
    return normalizedSections
  }

  protected getSelectedSection = (contentCategory): DropdownKey => {
    if (this.state.selectedSection) {
      return this.state.selectedSection.key
    } else {
      const selectedSection = this.state.sections.filter((x) => x.value === contentCategory)
      return selectedSection.length ? selectedSection[0].key : null
    }
  }

  protected onDropdownChange = (e) => {
    this.setState(({ selectedSection: e.selected }))
    this.updateProperty('contentCategory', e.selected.value)
    const selectedSection = this.state.allSections.find((x) => x.category_type_id === e.selected.key)
    this.updateProperty('categoryTypeId', selectedSection && selectedSection.category_type_id
      ? selectedSection.category_type_id
      : DEFAULT_CATEGORY_TYPE_ID)
  }

  protected getClassNames(data: any, controlType: string = '') {
    switch (controlType) {
      case CONTROL_CONTAINER:
        return [
          this.state.isValid || data.typeId || data.isCloneItem ? 'enabled' : 'disabled',
        ]
      case CONTROL_FETCH_BUTTON:
        return [
          this.state.sourceUrl || data.typeId || data.isCloneItem ? 'enabled' : 'disabled',
        ]
      default: return null
    }
  }

  protected getLocationOptions = (maxOptions) => {
    this.setState({
      locationOptions: Array.from({length: maxOptions}, (_, i) => i + 1).map((index) =>  {
        return {
          key: index,
          value: index,
          description: getSponsoredName(index),
        }
      }),
    })
  }

  protected setAnInterval = (end) => {
    return new Promise((resolve, reject) => {
    this._interval = setInterval(() => {
      const now = new Date()
      const timeToEnd = differenceInSeconds(new Date(end), now)
      if (timeToEnd < 0) {
        clearInterval(this._interval)
        this.setState({ currentTime: now })
      }
    }, 1000)
    })
  }

  protected setIntervals = () => {
    const {nextIssue} = this.state.activeCommunity
    if (nextIssue && isBeforeCutoff(nextIssue)) {
      this.setAnInterval(nextIssue.cutoff).then(() => this.setAnInterval(nextIssue.production_cutoff))
    }
    if (nextIssue && isInCutoffWindow(nextIssue)) {
      this.setAnInterval(nextIssue.production_cutoff)
    }
  }

}